export default defineAppConfig({
    title: `Alex & Hayley's Wedding Gallery`,
    theme: {
        colors: {
            primary: '#667e67',
        },
    },
    ui: {
        primary: 'green',
        gray: 'neutral',
    }
})
