import { default as index_46clientdHjMB5h8V9Meta } from "/opt/build/repo/pages/index.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/opt/build/repo/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
export default [
  {
    name: "index",
    path: "/",
    component: () => createClientPage(() => import("/opt/build/repo/pages/index.client.vue").then(m => m.default || m))
  }
]